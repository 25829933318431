import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICountriesList {
  id: number;
  country: string;
  path: string;
}

interface IEuropeCluster {
  shouldChangePath: boolean;
  list: ICountriesList[];
}

const initialState: IEuropeCluster = {
  shouldChangePath: false,
  list: [],
};

export const europeClusterSlice = createSlice({
  name: 'europeCluster',
  initialState,
  reducers: {
    setShouldChangePath: (state, { payload }: PayloadAction<boolean | undefined>) => {
      state.shouldChangePath = payload || false;
    },
    setCountriesList: (state, { payload }: PayloadAction<ICountriesList[]>) => {
      state.list = payload;
    },
  },
});

export default europeClusterSlice.reducer;
export const { setShouldChangePath, setCountriesList } = europeClusterSlice.actions;
