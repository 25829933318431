import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { initialAxios as axios } from 'Api/Api';
import { AxiosError, AxiosRequestConfig, CancelTokenSource, Method } from 'axios';
import { IToastError, IToastOptions, IToastSuccess } from 'interfaces/IBaseApi';
import { IMonitoringRecords } from 'interfaces/IMonitoringResponse';
import { ISummary } from 'interfaces/ISummary';
import get from 'lodash.get';
import {
  getAllAvailableEntitiesQuery,
  getAllAvailableEntitiesTransformResponse,
  getAllEntitiesQuery,
  getAllEntitiesTransformResponse,
  IGetAllAvailableEntitiesTransformResponse,
  IGetAllEntitiesTransformResponse,
} from './entities/EntitiesService';
import {
  getDownloadFileQuery,
  getDownloadFileTransformResponse,
  getUploadFileQuery,
  getUploadFileTransformResponse,
} from './filev2/filev2';
import {
  getHistoryQuery,
  getHistoryTransformResponse,
  HistoryParams,
  HistoryParsed,
} from './history';
import { getApiHost } from './host/HostService';
import {
  getPayloadDetailsQuery,
  getRecordDetailsQuery,
  getRecordsFromTraceIdQuery,
  getStatusDetailsTransformResponse,
  getStatusQuery,
  getStatusTransformResponse,
  getSummaryDetailsQuery,
  getSummaryQuery,
  IMonitoringParams,
  MonitoringDetailsResponse,
  MonitoringResponse,
} from './monitoring/monitoring';

export interface BaseQueryFnArgs {
  url: string;
  method?: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
  headers?: AxiosRequestConfig['headers'];
  cancelToken?: AxiosRequestConfig['cancelToken'];
  /**
   * This is the toastOptions parameter
   * @property {string} successMessage - This is the success message or default message in case of nested string failure
   * @property {string} nestedSuccessString - This is the nested success data key
   * @property {string} errorMessage - This is the error message or default message in case of nested string failure
   * @property {string} nestedErrorString - This is the nested error string
   */
  toastOptions?: IToastOptions;
}

export const axiosBaseQuery =
  ({ baseUrl }: { baseUrl: string }): BaseQueryFn<BaseQueryFnArgs, unknown, unknown> =>
  async ({ url, method, data, params, headers, cancelToken, toastOptions }) => {
    /* istanbul ignore else */
    if (url.includes('feedback-service/v1')) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      /* istanbul ignore else */
      if (timezone) {
        // eslint-disable-next-line no-param-reassign
        headers = { ...headers, timezone, country: params?.country };
      }
    }
    return getResult(baseUrl, url, method, data, params, headers, toastOptions, cancelToken);
  };

const getResult = async (
  baseUrl: string,
  url: string,
  method: Method | undefined,
  data: unknown,
  params: unknown,
  headers: Record<string, string> | undefined,
  toastOptions?: IToastOptions,
  cancelToken?: CancelTokenSource['token']
): Promise<
  | {
      data: never;
      meta: Record<string, string>;
      error?: undefined;
    }
  | {
      error: {
        status: number | undefined;
        data: string;
      };
      data?: undefined;
      meta?: undefined;
    }
> => {
  try {
    const result = await axios({ url: baseUrl + url, method, data, params, headers, cancelToken });
    const toast = toastOptions as IToastSuccess;
    if (toast?.nestedSuccessString || toast?.successMessage) {
      const toastService = useToast();
      toastService.notify({
        type: TypeToast.SUCCESS,
        message: get(result.data, toast?.nestedSuccessString, toast?.successMessage),
      });
    }
    return { data: result.data, meta: result.headers };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    const toast = toastOptions as IToastError;
    if (toast?.errorMessage || toast?.nestedErrorString) {
      const toastService = useToast();
      toastService.notify({
        type: TypeToast.ERROR,
        message: get(err.response?.data, toast?.nestedErrorString, toast?.errorMessage),
      });
    }
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};

export const emptySplitApi = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({ baseUrl: getApiHost() }),
  endpoints: (builder) => ({
    getStatus: builder.query<MonitoringResponse, IMonitoringParams>({
      query: getStatusQuery,
      transformResponse: getStatusTransformResponse,
    }),
    getSummary: builder.query<ISummary, IMonitoringParams>({
      query: getSummaryQuery,
    }),
    getRecordDetails: builder.query<MonitoringDetailsResponse, IMonitoringParams>({
      query: getRecordDetailsQuery,
      transformResponse: getStatusDetailsTransformResponse,
    }),
    getSummaryDetails: builder.query<ISummary, IMonitoringParams>({
      query: getSummaryDetailsQuery,
    }),
    getPayloadDetails: builder.query<IMonitoringRecords, IMonitoringParams>({
      query: getPayloadDetailsQuery,
    }),
    getAllEntities: builder.query<IGetAllEntitiesTransformResponse, IMonitoringParams>({
      query: getAllEntitiesQuery,
      transformResponse: getAllEntitiesTransformResponse,
    }),
    getAllAvailableEntities: builder.query<IGetAllAvailableEntitiesTransformResponse, void>({
      query: getAllAvailableEntitiesQuery,
      transformResponse: getAllAvailableEntitiesTransformResponse,
    }),
    uploadFile: builder.mutation({
      query: getUploadFileQuery,
      transformResponse: getUploadFileTransformResponse,
    }),
    downloadFile: builder.mutation({
      query: getDownloadFileQuery,
      transformResponse: getDownloadFileTransformResponse,
    }),
    getRecordsFromTraceId: builder.query<Array<IMonitoringRecords>, IMonitoringParams>({
      query: getRecordsFromTraceIdQuery,
    }),
    getHistory: builder.query<HistoryParsed, HistoryParams>({
      query: getHistoryQuery,
      transformResponse: getHistoryTransformResponse,
    }),
  }),
});

export const {
  useGetStatusQuery,
  useGetSummaryQuery,
  useGetRecordDetailsQuery,
  useGetSummaryDetailsQuery,
  useGetPayloadDetailsQuery,
  useGetAllEntitiesQuery,
  useGetAllAvailableEntitiesQuery,
  useUploadFileMutation,
  useGetRecordsFromTraceIdQuery,
  useDownloadFileMutation,
  useGetHistoryQuery,
} = emptySplitApi;

export { getResult };
