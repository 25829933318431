import { ThemeOptions } from '@material-ui/core';
import palette from './palette';

declare module '@material-ui/core/styles/createTypography' {
  export interface Typography {
    fontFamilySecondary?: string;
  }
}

export default {
  fontFamily: 'Work Sans, sans-serif',
  fontFamilySecondary: 'Barlow, sans-serif',
  h1: {
    color: palette?.text?.primary,
    fontWeight: 500,
    fontSize: '2.5rem',
    lineHeight: '1.2',
  },
  h2: {
    color: palette?.text?.primary,
    fontWeight: 500,
    fontSize: '2rem',
    lineHeight: '1.2',
  },
  h3: {
    color: palette?.text?.primary,
    fontWeight: 500,
    fontSize: '1.5rem',
    lineHeight: '1.2',
  },
  h4: {
    color: palette?.text?.primary,
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: '1.2',
  },
  h5: {
    color: palette?.text?.primary,
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1.2',
  },
  h6: {
    color: palette?.text?.primary,
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: '1.2',
  },
  body1: {
    color: palette?.text?.primary,
    fontSize: '1rem',
    lineHeight: '1.5',
  },
  body2: {
    color: palette?.text?.primary,
    fontSize: '0.875rem',
    lineHeight: '1.5',
  },
  button: {
    color: palette?.text?.primary,
    fontSize: '1rem',
    textTransform: 'uppercase',
  },
  caption: {
    color: palette?.text?.primary,
    fontSize: '0.75rem',
    lineHeight: '1.5',
  },
} as ThemeOptions['typography'];
