import { combineReducers, configureStore, PreloadedState, Store } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import downloadDataReducer from 'features/data-management/downloadData';
import historyReducer from 'features/data-management/history';
import uploadDataReducer from 'features/data-management/uploadData';
import europeClusterReducer from 'features/europeCluster/europeCluster';
import featureToggleReducer from 'features/featureToggle/featureToggle';
import { emptySplitApi } from 'services/baseApi';

export const rootReducer = combineReducers({
  uploadData: uploadDataReducer,
  downloadData: downloadDataReducer,
  history: historyReducer,
  europeCluster: europeClusterReducer,
  featureToggle: featureToggleReducer,
  [emptySplitApi.reducerPath]: emptySplitApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>): Store =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['downloadData/selectStartDate', 'downloadData/selectEndDate'],
          ignoredPaths: ['downloadData.startDate', 'downloadData.endDate'],
        },
      }).concat(emptySplitApi.middleware),
  });

export const store = setupStore();

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
