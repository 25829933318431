export const allowedToChangePath: string[] = [
  '/api/data-ingestion-bff/upload/',
  '/api/data-ingestion-bff/export/',
  '/api/data-ingestion-bff/upload/history',
  '/api/data-ingestion-bff/v1/payload-validation',
  '/api/data-ingestion-bff/feedback-service/v1/',
  '/api/data-ingestion-bff/orders/download',
];

export const unallowedToChangePath: string[] = [];
