import { BaseQueryArg, BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { useAuthenticationService } from 'admin-portal-shared-services';
import { SimpleSelectViewProps } from 'components/SimpleSelect/SimpleSelectView';
import { formatMessage } from 'i18n/formatters';
import {
  IBulkEntities,
  IBulkEntitiesWithVendors,
  IDownloadEntitiesByVendor,
  IEntitiesByVendor,
} from 'interfaces/IEntity';
import { AvailableMethods, AvailableVersions } from 'interfaces/IFile';
import orderBy from 'lodash.orderby';
import { IActionsList } from 'pages/DataManagement/DataManagementPage';
import { BaseQueryFnArgs } from 'services/baseApi';
import RequestTraceIdService from 'services/requestTraceId/RequestTraceIdService';

export interface IAvailableItem {
  method: `${Uppercase<AvailableMethods>}`;
  versions: AvailableVersions[];
}

export interface IEntityItem {
  id: string;
  label: string;
  available?: IAvailableItem[];
}

export interface IGetAllEntities {
  vendorId: string;
  country: string;
}

export interface GetAllEntitiesParams {
  country: string;
}

export interface GetBulkEntitiesParams {
  country: string;
}

type IMethodsParsedObject = {
  [key: string]: string;
};

export const methodsParserObject: IMethodsParsedObject = {
  post: 'Inclusion',
  put: 'Inclusion',
  patch: 'Update',
  delete: 'Exclusion',
  get: 'Search',
};

export interface IGenerateEntitiesList {
  uploadEntities: SimpleSelectViewProps['options'];
  downloadEntities: SimpleSelectViewProps['options'];
}

export function generateEntitiesList(entities: IEntityItem[]): IGenerateEntitiesList {
  const uploadEntities = orderBy(
    entities.map((entity) => ({
      label: entity.label,
      value: entity.id,
    })),
    ['label'],
    'asc'
  );

  const downloadEntities = orderBy(
    entities.reduce((acc: SimpleSelectViewProps['options'], entity: IEntityItem) => {
      const downloadOptions = entity.available?.find((item) => item.method === 'GET');
      return [
        ...acc,
        ...(downloadOptions?.versions.map((version) => ({
          label: `${entity.label} (${version})`,
          value: `${entity.id}/download/${version.toLowerCase()}`,
        })) || []),
      ];
    }, []),
    ['label'],
    'asc'
  );

  return { uploadEntities, downloadEntities };
}

export function generateActionList(entities: IEntityItem[]): IActionsList {
  return entities.reduce((acc, item) => {
    return {
      ...acc,
      [item.id]: genetareActionsPerVersions(item.available),
    };
  }, {});
}

function genetareActionsPerVersions(available: IEntityItem['available']) {
  return orderBy(
    available?.reduce((actionsAcc: SimpleSelectViewProps['options'], actionItem) => {
      if (actionItem.method === 'GET') return actionsAcc;
      return [
        ...actionsAcc,
        ...actionItem.versions.reduce(
          (versionsAcc: SimpleSelectViewProps['options'], versionItem) => {
            return [
              ...versionsAcc,
              {
                value: `${actionItem.method.toLowerCase()}/${versionItem}`,
                label: `${
                  methodsParserObject[actionItem.method.toLowerCase()]
                } (${versionItem}) - ${actionItem.method}`,
              },
            ];
          },
          []
        ),
      ];
    }, []),
    ['label'],
    'asc'
  );
}

export interface IGetAllEntitiesTransformResponse {
  uploadEntities: IEntitiesByVendor['entities'];
  downloadEntities: IDownloadEntitiesByVendor['entities'];
  bulkUploadEntities: IBulkEntitiesWithVendors[];
}

export const getAllEntitiesQuery = ({
  country,
}: GetAllEntitiesParams): BaseQueryArg<BaseQueryFn<BaseQueryFnArgs, unknown, unknown>> => {
  return {
    url: `/upload/available/v2`,
    headers: { country, requestTraceId: RequestTraceIdService.create() },
    toastOptions: {
      errorMessage: formatMessage({ id: 'ErrorMessages.FAIL_TO_LOAD_ENTITIES' }),
      nestedErrorString: 'data.errorCode',
    },
  };
};

export const getAllAvailableEntitiesQuery = (): BaseQueryFnArgs => {
  const authentication = useAuthenticationService();
  const country = authentication.getCountryB2C() || 'BR';
  return {
    url: '/upload/all-available',
    headers: { country },
    toastOptions: {
      errorMessage: formatMessage({ id: 'ErrorMessages.FAIL_TO_LOAD_ALL_ENTITIES' }),
      nestedErrorString: 'data.errorCode',
    },
  };
};

export interface IGetAllAvailableEntitiesTransformResponse {
  actionsList: IActionsList;
  entitiesList: SimpleSelectViewProps['options'];
}

export const getAllAvailableEntitiesTransformResponse = (
  apiResponse: IEntityItem[]
): IGetAllAvailableEntitiesTransformResponse => {
  const actionsList = generateActionList(apiResponse);

  const { uploadEntities } = generateEntitiesList(apiResponse);

  return {
    actionsList,
    entitiesList: uploadEntities,
  };
};

interface IGetUploadEntities {
  uploadEntities: IEntitiesByVendor[];
  downloadEntities: IDownloadEntitiesByVendor[];
  bulkUploadEntities: IBulkEntitiesWithVendors[];
}

function getUploadEntities(data: IBulkEntities[]): IEntitiesByVendor[] {
  const uploadEntities: IEntitiesByVendor[] = [];

  data.forEach((entityItem) => {
    entityItem.available.forEach((availableItem) => {
      availableItem.vendors.forEach((vendorItem) => {
        if (availableItem.method === 'GET') return;
        const vendorExists = uploadEntities.find((uploadItem) => uploadItem.id === vendorItem.id);
        if (vendorExists) {
          const entityExists = vendorExists.entities.find(
            (entityListItem) => entityListItem.id === entityItem.id
          );
          if (entityExists) {
            entityExists.actions.push({
              id: `${availableItem.method.toLowerCase()}/${availableItem.version.toLowerCase()}`,
              label: `${methodsParserObject[availableItem.method.toLowerCase()]} (${
                availableItem.version
              }) - ${availableItem.method}`,
              value: `${availableItem.method.toLowerCase()}/${availableItem.version}`,
            });
          } else {
            vendorExists.entities.push({
              id: entityItem.id,
              label: entityItem.label,
              actions: [
                {
                  id: `${availableItem.method.toLowerCase()}/${availableItem.version.toLowerCase()}`,
                  label: `${methodsParserObject[availableItem.method.toLowerCase()]} (${
                    availableItem.version
                  }) - ${availableItem.method}`,
                  value: `${availableItem.method.toLowerCase()}/${availableItem.version}`,
                },
              ],
            });
          }
        } else {
          uploadEntities.push({
            id: vendorItem.id,
            name: vendorItem.name,
            entities: [
              {
                id: entityItem.id,
                label: entityItem.label,
                actions: [
                  {
                    id: `${availableItem.method.toLowerCase()}/${availableItem.version.toLowerCase()}`,
                    label: `${methodsParserObject[availableItem.method.toLowerCase()]} (${
                      availableItem.version
                    }) - ${availableItem.method}`,
                    value: `${availableItem.method.toLowerCase()}/${availableItem.version}`,
                  },
                ],
              },
            ],
          });
        }
      });
    });
  });

  return uploadEntities;
}

function getBulkUploadEntities(data: IBulkEntities[]): IBulkEntitiesWithVendors[] {
  const bulkUploadEntities: IBulkEntitiesWithVendors[] = [];

  data.forEach((entityItem) => {
    entityItem.available.forEach((availableItem) => {
      if (availableItem.method === 'GET') return;
      const bulkVendors = availableItem.vendors.filter((vendorItem) => !!vendorItem.bulk);
      if (bulkVendors.length) {
        const entityExists = bulkUploadEntities.find((bulkItem) => bulkItem.id === entityItem.id);
        if (entityExists) {
          entityExists.actions.push({
            id: `${availableItem.method.toLowerCase()}/${availableItem.version.toLowerCase()}`,
            label: `${methodsParserObject[availableItem.method.toLowerCase()]} (${
              availableItem.version
            }) - ${availableItem.method}`,
            value: `${availableItem.method.toLowerCase()}/${availableItem.version}`,
            vendors: bulkVendors.map((bulkItem) => ({
              id: bulkItem.id,
              name: bulkItem.name,
              model: bulkItem.model,
            })),
          });
        } else {
          bulkUploadEntities.push({
            id: entityItem.id,
            label: entityItem.label,
            actions: [
              {
                id: `${availableItem.method.toLowerCase()}/${availableItem.version.toLowerCase()}`,
                label: `${methodsParserObject[availableItem.method.toLowerCase()]} (${
                  availableItem.version
                }) - ${availableItem.method}`,
                value: `${availableItem.method.toLowerCase()}/${availableItem.version}`,
                vendors: bulkVendors.map((bulkItem) => ({
                  id: bulkItem.id,
                  name: bulkItem.name,
                  model: bulkItem.model,
                })),
              },
            ],
          });
        }
      }
    });
  });

  return bulkUploadEntities;
}

function getDownloadEntities(data: IBulkEntities[]): IDownloadEntitiesByVendor[] {
  const downloadEntities: IDownloadEntitiesByVendor[] = [];

  data.forEach((entityItem) => {
    entityItem.available.forEach((availableItem) => {
      availableItem.vendors.forEach((vendorItem) => {
        if (availableItem.method !== 'GET') return;
        const vendorExists = downloadEntities.find((uploadItem) => uploadItem.id === vendorItem.id);
        if (vendorExists) {
          vendorExists.entities.push({
            id: `${entityItem.id}-${availableItem.version}`,
            label: `${entityItem.label} (${availableItem.version})`,
            value: `${entityItem.id}/download/${availableItem.version.toLowerCase()}`,
          });
        } else {
          downloadEntities.push({
            id: vendorItem.id,
            name: vendorItem.name,
            entities: [
              {
                id: `${entityItem.id}-${availableItem.version}`,
                label: `${entityItem.label} (${availableItem.version})`,
                value: `${entityItem.id}/download/${availableItem.version.toLowerCase()}`,
              },
            ],
          });
        }
      });
    });
  });

  return downloadEntities;
}

function getParsedEntities(data: IBulkEntities[]): IGetUploadEntities {
  const uploadEntities: IEntitiesByVendor[] = getUploadEntities(data);
  const downloadEntities: IDownloadEntitiesByVendor[] = getDownloadEntities(data);
  const bulkUploadEntities: IBulkEntitiesWithVendors[] = getBulkUploadEntities(data);

  return { uploadEntities, downloadEntities, bulkUploadEntities };
}

export const getAllEntitiesTransformResponse = (
  apiResponse: IBulkEntities[],
  ...props: [unknown, { vendor: string }]
): IGetAllEntitiesTransformResponse => {
  const [, { vendor }] = props;

  const { uploadEntities, downloadEntities, bulkUploadEntities } = getParsedEntities(apiResponse);

  const uploadEntitiesByVendor = orderBy(
    uploadEntities.find((item) => item.id === vendor)?.entities,
    ['id'],
    'asc'
  );

  const downloadEntitiesByVendor = orderBy(
    downloadEntities.find((item) => item.id === vendor)?.entities,
    ['id'],
    'asc'
  );

  return {
    uploadEntities: uploadEntitiesByVendor,
    downloadEntities: downloadEntitiesByVendor,
    bulkUploadEntities,
  };
};
