import { useSelector } from 'react-redux';
import { initialAxios } from 'Api/Api';
import { RootState } from 'app/store';
import { AxiosRequestConfig } from 'axios';
import { allowedToChangePath, unallowedToChangePath } from 'consts/europeClusters';
import { ICountriesList } from 'features/europeCluster/europeCluster';
import { ICountry } from 'interfaces/ICountry';

export type Countries = ICountry['code'] | 'default' | string;

const allowToChangePath = (url: string): boolean =>
  !!(
    allowedToChangePath.find((item) => url.includes(item)) &&
    !unallowedToChangePath.find((item) => url.includes(item))
  );

export const resolveRequestConfig = (
  config: AxiosRequestConfig,
  shouldChangePath: boolean,
  list: ICountriesList[]
): AxiosRequestConfig => {
  if (shouldChangePath) {
    if (typeof config.url === 'string') {
      const result = allowToChangePath(config.url);
      if (result) {
        const [, path] = config.url.split('/api/');
        const url = new URLSearchParams(config?.url);
        const urlCountry = url.get('country') || url.get('countries');
        const country = config?.headers?.country || urlCountry;
        const baseUrl = list.find((item) => item.country === country)?.path || '/api/';
        return {
          ...config,
          url: `${baseUrl}${path}`,
        };
      }
    }
  }

  return config;
};

export function useRequestInterceptor(): void {
  const { shouldChangePath, list } = useSelector((state: RootState) => state.europeCluster);

  initialAxios.interceptors.request.use((config: AxiosRequestConfig) =>
    resolveRequestConfig(config, shouldChangePath, list)
  );
}
