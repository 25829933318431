import { BaseQueryArg, BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { CancelTokenSource } from 'axios';
import { formatMessage } from 'i18n/formatters';
import { BaseQueryFnArgs } from 'services/baseApi';

interface UploadFileParams {
  file: FormData;
  vendors?: FormData;
  entity: string;
  version: string;
  method: string;
  country: string;
  requestTraceId: string;
  offset: number;
  vendorId: string;
  cancelToken: CancelTokenSource['token'];
  bulk: boolean;
}

interface UploadHeaders {
  fileType: string;
  country: string;
  requestTraceId: string;
  offset: number;
  vendorId?: string;
  'Content-Type': string;
}

export const getUploadFileQuery = ({
  file,
  entity,
  version,
  method,
  country,
  requestTraceId,
  offset,
  vendorId,
  cancelToken,
  bulk,
}: UploadFileParams): BaseQueryArg<BaseQueryFn> => {
  const url = bulk ? `/upload/bulk/${entity}/${version}` : `/upload/${entity}/${version}`;

  const headers: UploadHeaders = {
    fileType: 'csv',
    country,
    requestTraceId,
    offset,
    vendorId,
    'Content-Type': 'multipart/form-data',
  };

  if (bulk) {
    delete headers.vendorId;
  }

  return {
    url,
    method,
    data: file,
    headers,
    cancelToken,
  };
};

export interface IUploadFileResponse {
  title: string;
  message: string;
  lines?: Record<string, unknown>[];
  vendors?: IUploadFileResponseVendors[];
}

export interface IUploadFileResponseVendors {
  name: string;
  requestTraceId: string;
  vendorId: string;
}

export interface IUploadFileResponseFailure {
  status?: number;
  data: {
    errorCode?: string;
    code?: string;
    message?: string;
    errorTitle?: string;
    detail?: string;
    lines?: Record<string, unknown>[];
    cancelled?: boolean;
  };
}

interface IUploadFile {
  desc: string;
  error: boolean;
  vendors?: IUploadFileResponseVendors[];
}

export const getUploadFileTransformResponse = (
  response: IUploadFile,
  meta,
  args: { requestTraceId: string }
): IUploadFileResponse => {
  return {
    title: response?.vendors?.length
      ? `${response?.vendors?.length} ${formatMessage({
          id: 'DataManagementPage.BULK_UPLOAD_RESPONSE_SUCCESS_TITLE',
        })}`
      : formatMessage({
          id: 'DataManagementPage.UPLOAD_RESPONSE_SUCCESS',
        }),
    message: response?.vendors?.length
      ? formatMessage({
          id: 'DataManagementPage.BULK_UPLOAD_RESPONSE_SUCCESS_MESSAGE',
        })
      : '',
    vendors: response?.vendors,
  };
};

interface DownloadFileParams {
  entity: string;
  startDate: string;
  endDate: string;
  status: string;
  accountId: string;
  country: string;
  vendorId: string;
}

interface DownloadFileSuccessResponse {
  error: boolean;
  desc: string;
  fileName: string;
  url: string;
}

type DownloadParams = {
  [key: string]: string;
};

export const getDownloadFileQuery = ({
  entity,
  startDate,
  endDate,
  status,
  accountId,
  country,
  vendorId,
}: DownloadFileParams): BaseQueryArg<BaseQueryFn<BaseQueryFnArgs, unknown, unknown>> => {
  const params: DownloadParams = {
    startDate,
    endDate,
    status,
    accountId,
  };
  Object.keys(params).forEach((key) => !params[key] && delete params[key]);
  return {
    url: `/${entity}`,
    method: 'GET',
    params,
    headers: {
      country,
      vendorId,
    },
    toastOptions: {
      successMessage: formatMessage({ id: 'DataManagementPage.DOWNLOAD_SUCCESSFUL' }),
      errorMessage: formatMessage({ id: 'RequestMessages.ERROR_HEADER' }),
      nestedErrorString: 'data.errorTitle',
    },
  };
};

export const getDownloadFileTransformResponse = (
  response: DownloadFileSuccessResponse
): DownloadFileSuccessResponse => response;
