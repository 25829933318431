import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { formatMessage } from 'i18n/formatters';
import { Error404 } from 'supplier-portal-shared-components';
import useStyles from './NotFound404.styles';

function NotFound404(): JSX.Element {
  const { notFound } = useStyles();

  const history = useHistory();
  const goBackHome = () => {
    history.push('/');
  };

  return (
    <Box pb={3} className={notFound}>
      <Error404
        buttonClickHandler={goBackHome}
        headerText={formatMessage({ id: 'Components.NOT_FOUND_HEADER_TEXT' })}
        subHeaderText={formatMessage({ id: 'Components.NOT_FOUND_SUBHEADER_TEXT' })}
        buttonText={formatMessage({ id: 'Components.NOT_FOUND_HOME_BTN' })}
      />
    </Box>
  );
}

export default NotFound404;
