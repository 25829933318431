export enum FeatureToggles {
  BEES_SYNC_EUROPE_CLUSTER = 'bees-sync-europe-cluster',
  BEES_SYNC_STOPLIGHT_LINKS = 'bees-sync-stoplight-links',
}

export enum FeatureTogglesWithoutOptimizely {
  BEES_SYNC_PAYLOAD_VALIDATOR = 'bees-sync-payload-validator',
}

export type FeatureToggleReduxKey = 'payloadValidator' | 'europeCluster';

export enum FeatureToggleKeys {
  'bees-sync-europe-cluster' = 'europeCluster',
}

export const featureTogglesWithoutOptimizelyState = {
  [FeatureTogglesWithoutOptimizely.BEES_SYNC_PAYLOAD_VALIDATOR]: false,
};

export const featureTogglesState = {
  [FeatureToggles.BEES_SYNC_EUROPE_CLUSTER]: false,
  [FeatureToggles.BEES_SYNC_STOPLIGHT_LINKS]: true,
};

export const featureTogglesInitialState = {
  ...featureTogglesWithoutOptimizelyState,
  ...featureTogglesState,
};

export enum FeatureToggleKeysWithoutOptimizely {
  'bees-sync-payload-validator' = 'payloadValidator',
}

export type FeatureTogglesKey = keyof typeof FeatureToggleKeys;

export type FeatureTogglesWithoutOptimizelyKey = keyof typeof FeatureToggleKeysWithoutOptimizely;

export type FeatureTogglesRedux = {
  [value in FeatureToggleReduxKey]: boolean;
};
