import { Heading } from '@hexa-ui/components';
import { styled } from 'themes';

export const ModalTitle = styled(Heading, {
  textAlign: 'center',
  fontSize: '1.75rem',
  lineHeight: 1.25,
});

export const ModalContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  paddingBottom: '$1',
  minHeight: '7.25rem',
  '@tablet': {
    maxWidth: '32rem',
  },
});

export const ButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 10,
});

export const ModalContainer = styled('div', {
  '& [role="alertdialog"]': {
    '@tablet': {
      minWidth: '36rem',
      transform: 'translate3d(-50%, calc(-50% - 20px), 0px)',
    },
  },
  '& [class*="image-false"]': {
    height: 'auto',
  },
  '& [class*="shouldAdjustPadding-true"]': {
    padding: '$8 $8 0',
  },
  variants: {
    isLoading: {
      true: {
        [`& ${ModalTitle}`]: {
          visibility: 'hidden',
        },
        [`& ${ButtonContainer}`]: {
          visibility: 'hidden',
        },
        [`& ${ModalContent}`]: {
          flexDirection: 'row',
          justifyContent: 'center',
          '& > div': {
            paddingTop: '$4',
          },
        },
      },
    },
    hasError: {
      true: {
        [`& ${ModalTitle}`]: {
          fontSize: '$6',
          textAlign: 'left',
          padding: 0,
        },
        [`& ${ModalContent}`]: {
          minHeight: 'auto',
        },
      },
    },
  },
});
