import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatMessage } from 'i18n/formatters';
import { IBulkEntitiesVendors } from 'interfaces/IEntity';
import { ITemplate } from 'interfaces/ITemplate';
import { IUploadMode, IUploadStepsVendors } from 'interfaces/IUploadSteps';
import { methodsParserObject } from 'services/entities/EntitiesService';
import {
  IUploadFileResponse,
  IUploadFileResponseFailure,
  IUploadFileResponseVendors,
} from 'services/filev2/filev2';
import RequestTraceIdService from 'services/requestTraceId/RequestTraceIdService';

export interface IUploadOptions {
  stoplightLinks: ITemplate[];
  templateLink: string;
  entity: string;
  action: string;
  mode: IUploadMode;
  actionName: string;
  method: string;
  selectedVendors: IBulkEntitiesVendors[];
  vendorsList: IBulkEntitiesVendors[];
  version: string;
  fileName: string;
  fileSize: number | null;
  confirmationModal: boolean;
  canUpload: boolean;
  uploadStatus: {
    title: string;
    message: string;
    lines: Record<string, unknown>[];
    vendors: IUploadFileResponseVendors[];
    cancelled?: boolean;
  };
  requestTraceId: string;
}

type ISetVendorsListTypes = 'insert' | 'delete' | 'insertAll' | 'deleteAll';

const uploadOptions: IUploadOptions = {
  stoplightLinks: [],
  templateLink: '',
  entity: '',
  action: '',
  actionName: '',
  mode: 'single',
  selectedVendors: [],
  vendorsList: [],
  method: '',
  version: '',
  fileName: '',
  fileSize: null,
  confirmationModal: false,
  canUpload: false,
  uploadStatus: {
    title: '',
    message: '',
    lines: [],
    vendors: [],
    cancelled: false,
  },
  requestTraceId: '',
};
const initialState: IUploadOptions = {
  ...uploadOptions,
};

const getTemplateLink = ({
  stoplightLinks,
  entity,
  action,
}: {
  stoplightLinks: ITemplate[];
  entity: string;
  action: string;
}): string => {
  const entityItem = stoplightLinks.find((item) => item.entity === entity);
  if (!entityItem) return '';
  const [method, version] = action.split('/');
  return entityItem?.methods?.[method]?.[version]?.url ?? '';
};

const getActionName = ({ action }: { action: string }): string => {
  const [method, version] = action.split('/');
  return `${methodsParserObject[method]} (${version}) - ${method.toUpperCase()}`;
};

const getMethodName = ({ action }: { action: string }): string => {
  const [method] = action.split('/');
  return method;
};

const getVersion = ({ action }: { action: string }): string => {
  const [_, version] = action.split('/');
  return version.toLowerCase();
};

export const uploadData = createSlice({
  name: 'uploadData',
  initialState,
  reducers: {
    selectEntity: (state, action: PayloadAction<string>) => {
      if (action.payload !== state.entity) {
        return {
          ...initialState,
          entity: action.payload,
          action: initialState.action,
          stoplightLinks: state.stoplightLinks,
          mode: state.mode,
        };
      }
      return state;
    },
    selectAction: (
      state,
      { payload }: PayloadAction<{ action: string; vendors: IBulkEntitiesVendors[] }>
    ) => {
      if (payload.action !== state.action) {
        state.action = payload.action;
        state.method = getMethodName({ action: payload.action });
        state.version = getVersion({ action: payload.action });
        state.vendorsList = payload.vendors;
        state.templateLink = getTemplateLink({
          stoplightLinks: state.stoplightLinks,
          entity: state.entity,
          action: payload.action,
        });
      }
    },
    selectFile: (
      state,
      { payload }: PayloadAction<{ fileName: string; fileSize: number | null }>
    ) => {
      state.fileName = payload.fileName;
      state.fileSize = payload.fileSize;
      state.actionName = getActionName({ action: state.action });
      state.method = getMethodName({ action: state.action });
      state.version = getVersion({ action: state.action });
      state.canUpload = false;
      state.requestTraceId = '';
      state.confirmationModal = true;
    },
    confirmFile: (state, { payload }: PayloadAction<string | undefined>) => {
      state.requestTraceId = payload || RequestTraceIdService.create();
      state.canUpload = true;
      state.confirmationModal = false;
    },
    setUploadSuccess: (state, { payload }: PayloadAction<IUploadFileResponse>) => {
      state.canUpload = false;
      state.uploadStatus.title = payload.title;
      state.uploadStatus.message = payload.message;
      state.uploadStatus.lines = [];
      state.uploadStatus.vendors = payload.vendors || [];
      state.uploadStatus.cancelled = false;
    },
    setUploadFailure: (state, { payload }: PayloadAction<IUploadFileResponseFailure>) => {
      state.canUpload = false;
      if (payload.data?.cancelled) {
        state.uploadStatus.title = formatMessage({ id: 'ErrorMessages.UPLOAD_STOPPED_BY_USER' });
        state.uploadStatus.message = formatMessage({
          id: 'ErrorMessages.UPLOAD_STOPPED_BY_USER_MESSAGE',
        });
        state.uploadStatus.lines = [];
        state.uploadStatus.cancelled = true;
      } else {
        const {
          errorCode,
          code,
          message,
          errorTitle,
          detail = formatMessage({ id: 'DataManagementPage.UPLOAD_GENERIC_MESSAGE' }),
          lines,
        } = payload.data;
        const fileError = [code as string, errorCode as string].includes('DTB10')
          ? formatMessage({ id: 'DataManagementPage.UPLOAD_GENERIC_MESSAGE' })
          : detail;
        state.uploadStatus.message = fileError;
        state.uploadStatus.title =
          errorTitle || message || formatMessage({ id: 'DataManagementPage.UPLOAD_GENERIC_TITLE' });
        state.uploadStatus.lines = lines || [];
        state.uploadStatus.cancelled = false;
      }
    },
    setMode: (state, { payload }: PayloadAction<IUploadOptions['mode']>) => ({
      ...initialState,
      stoplightLinks: state.stoplightLinks,
      mode: payload,
    }),
    setStoplightLinks: (state, { payload }: PayloadAction<ITemplate[]>) => {
      state.stoplightLinks = payload;
    },
    setSelectedVendorsList: (
      state,
      {
        payload,
      }: PayloadAction<{
        item: IBulkEntitiesVendors | IBulkEntitiesVendors[];
        type: ISetVendorsListTypes;
      }>
    ) => {
      if (payload.type === 'insert') {
        state.selectedVendors.push(payload.item as IUploadStepsVendors);
      }
      if (payload.type === 'delete') {
        state.selectedVendors = state.selectedVendors.filter(
          (vendorItem) => vendorItem.id !== (payload.item as IUploadStepsVendors).id
        );
      }
      if (payload.type === 'insertAll' && (payload.item as IUploadStepsVendors[]).length) {
        state.selectedVendors = payload.item as IUploadStepsVendors[];
      }
      if (payload.type === 'deleteAll') {
        state.selectedVendors = [];
      }
    },
    resetToInitialState: (
      state,
      { payload }: PayloadAction<{ shouldKeepMode?: boolean } | undefined>
    ) => ({
      ...initialState,
      stoplightLinks: state.stoplightLinks,
      mode: payload?.shouldKeepMode ? state.mode : initialState.mode,
    }),
  },
});

export default uploadData.reducer;
export const {
  selectEntity,
  selectAction,
  selectFile,
  resetToInitialState,
  confirmFile,
  setUploadSuccess,
  setUploadFailure,
  setMode,
  setSelectedVendorsList,
  setStoplightLinks,
} = uploadData.actions;
