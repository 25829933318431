import React from 'react';
import { useSelector } from 'react-redux';
import { BarChart2, Checkmark2, Download, HelpCircle } from '@admin-portal-shared-components/icons';
import {
  SidebarItem,
  SidebarUtilitiesItem,
} from 'admin-portal-shared-services/dist/services/sidebar/SidebarService.types';
import { RootState } from 'app/store';
import { BEES_SYNC_DOCUMENTATION_LINK } from 'consts/configs';
import { formatMessage } from 'i18n/formatters';
import { DATAMANAGEMENTPAGE_URL, MONITORINGPAGE_URL, PAYLOADVALIDATORPAGE_URL } from 'Router';

interface UseMenuFeatureToggleReturn {
  menuItems: SidebarItem[];
  menuUtilitiesItems: SidebarUtilitiesItem[];
}

const DataIcon = () => <Download />;
const MonitoringIcon = () => <BarChart2 style={{ width: '22px', height: '22px' }} />;
const PayloadIcon = () => <Checkmark2 />;
const HelpIcon = () => <HelpCircle />;

const initialMenuItems = [
  {
    id: 'data',
    title: '',
    icon: DataIcon,
    path: '/',
  },
  {
    id: 'monitoring',
    title: '',
    icon: MonitoringIcon,
    path: '/ingestion/monitoring',
  },
];

const menuUtilitiesItems = [
  {
    id: 'help',
    title: formatMessage({ id: 'Components.MENU_HELP' }),
    icon: HelpIcon,
    path: BEES_SYNC_DOCUMENTATION_LINK,
    target: '_blank',
  },
] as SidebarUtilitiesItem[];

export function useMenuOptions(): UseMenuFeatureToggleReturn {
  const featureToggles = useSelector((state: RootState) => state.featureToggle);

  const [menuItems, setMenuItems] = React.useState<SidebarItem[]>(initialMenuItems);

  React.useEffect(() => {
    if (!featureToggles.isSomethingLoading) {
      setMenuItems([
        {
          id: 'data',
          title: formatMessage({ id: 'DataManagementPage.TITLE' }),
          icon: DataIcon,
          path: DATAMANAGEMENTPAGE_URL,
        },
        {
          id: 'monitoring',
          title: formatMessage({ id: 'MonitoringPage.TITLE' }),
          icon: MonitoringIcon,
          path: MONITORINGPAGE_URL,
        },
        ...(featureToggles.payloadValidator
          ? [
              {
                id: 'payload',
                title: formatMessage({ id: 'PayloadValidatorPage.TITLE' }),
                icon: PayloadIcon,
                path: PAYLOADVALIDATORPAGE_URL,
              },
            ]
          : []),
      ]);
    }
  }, [featureToggles.isSomethingLoading]);

  return { menuItems, menuUtilitiesItems };
}
