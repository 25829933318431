import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    notFound: {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      '& [class*="errorImage"]': {
        width: '100%',
        maxWidth: '450px',
        height: 'auto',
        marginBottom: theme.spacing(4),
      },
    },
  })
);
