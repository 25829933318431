import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ButtonProps, LoadingDots, Modal, Paragraph, Select } from '@hexa-ui/components';
import { formatMessage } from 'i18n/formatters';
import { ICountry } from 'interfaces/ICountry';
import { ICountryModalState } from './CountryModal';
import {
  ButtonContainer,
  ModalContainer,
  ModalContent,
  ModalTitle,
} from './CountryModalView.styles';

export interface ICountryModalView {
  modalStatus: ICountryModalState;
  countriesAvailable: ICountry[];
  onSelectCountry: (country: string) => void;
}

function CountryModalView({
  modalStatus,
  countriesAvailable,
  onSelectCountry,
}: ICountryModalView): JSX.Element {
  const [modalSelectedCountry, setModalSelectedCountry] = useState<string>('');

  const history = useHistory();

  const { isModalLoading, isModalOpen, hasError } = modalStatus;

  let content = {
    title: formatMessage({ id: 'Components.COUNTRY_MODAL_TITLE' }),
    description: formatMessage({ id: 'Components.COUNTRY_MODAL_SUBTITLE' }),
    cancelButton: {
      label: formatMessage({ id: 'Components.COUNTRY_MODAL_CANCEL_BUTTON' }),
      variant: 'secondary' as ButtonProps['variant'],
    },
  };

  if (hasError) {
    content = {
      title: hasError.errorTitle,
      description: hasError.errorDetails,
      cancelButton: {
        label: formatMessage({ id: 'Components.COUNTRY_MODAL_BACK_BUTTON' }),
        variant: 'primary',
      },
    };
  }

  const getModalContent = () => {
    if (isModalLoading) return <LoadingDots size="xlarge" data-testid="transition-spinner" />;
    return (
      <>
        <Paragraph>{content.description}</Paragraph>
        {!hasError && !!countriesAvailable.length && (
          <Select.Root
            value={modalSelectedCountry}
            placeholder={formatMessage({ id: 'Components.COUNTRY_MODAL_SELECT_PLACEHOLDER' })}
            onChange={(value) => setModalSelectedCountry(value)}
            shape="pill"
          >
            {countriesAvailable.map((country) => (
              <Select.Option key={country.code} value={country.code}>
                {country.name}
              </Select.Option>
            ))}
          </Select.Root>
        )}
      </>
    );
  };

  return (
    <ModalContainer isLoading={isModalLoading} hasError={!!hasError}>
      <Modal.Root
        open={isModalOpen}
        title={<ModalTitle>{content.title}</ModalTitle>}
        actions={
          <ButtonContainer>
            <Modal.Cancel>
              <Button onClick={() => history.push('/')} variant={content.cancelButton.variant}>
                {content.cancelButton.label}
              </Button>
            </Modal.Cancel>
            {!hasError && (
              <Modal.Action>
                <Button
                  onClick={() => onSelectCountry(modalSelectedCountry)}
                  disabled={!modalSelectedCountry}
                >
                  {formatMessage({
                    id: 'Components.COUNTRY_MODAL_CONFIRM_BUTTON',
                  })}
                </Button>
              </Modal.Action>
            )}
          </ButtonContainer>
        }
      >
        <ModalContent>{getModalContent()}</ModalContent>
      </Modal.Root>
    </ModalContainer>
  );
}

export default CountryModalView;
