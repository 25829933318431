import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FeatureToggleKeys,
  FeatureToggleKeysWithoutOptimizely,
  FeatureToggles,
  featureTogglesInitialState,
  FeatureTogglesKey,
  FeatureTogglesRedux,
  featureTogglesState,
  FeatureTogglesWithoutOptimizelyKey,
} from 'consts/featureTogglesMap';

export interface IFeatureToggle extends FeatureTogglesRedux {
  isError: boolean;
  isSomethingLoading: boolean;
}

const transformFeatureNames = (data: FeatureToggles | typeof featureTogglesState) => {
  const featureToggles = {} as FeatureTogglesRedux;

  Object.entries(data).forEach(([featureKey, value]) => {
    const featureToggle = FeatureToggleKeys[featureKey as FeatureTogglesKey];

    featureToggles[featureToggle] = value;
  });

  return featureToggles;
};

const initialState: IFeatureToggle = {
  isError: false,
  isSomethingLoading: true,
  ...transformFeatureNames(featureTogglesInitialState),
};

export const featureToggleSlice = createSlice({
  name: 'featureToggle',
  initialState,
  reducers: {
    setIsError: () => ({
      ...initialState,
      isError: true,
      isSomethingLoading: false,
    }),
    changeToggleWithoutOptimizely: (
      state,
      {
        payload,
      }: PayloadAction<{ toggleKey: FeatureTogglesWithoutOptimizelyKey; toggleValue: boolean }>
    ) => {
      state[FeatureToggleKeysWithoutOptimizely[payload.toggleKey]] = payload.toggleValue;
    },
    changeFeatureToggle: (state, { payload }) =>
      ({
        ...state,
        ...transformFeatureNames(payload),
        isError: false,
        isSomethingLoading: false,
      } as IFeatureToggle),
  },
});

export default featureToggleSlice.reducer;
export const { setIsError, changeFeatureToggle, changeToggleWithoutOptimizely } =
  featureToggleSlice.actions;
