import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IDownloadOptions {
  entity: string;
  startDate: Date | null;
  endDate: Date | null;
  status: string;
  accountId: string;
}

const downloadOptions: IDownloadOptions = {
  entity: '',
  startDate: null,
  endDate: null,
  status: '',
  accountId: '',
};

const initialState = {
  ...downloadOptions,
};

export const downloadData = createSlice({
  name: 'downloadData',
  initialState,
  reducers: {
    selectEntity: (state, action: PayloadAction<string>) => {
      state.entity = action.payload;
    },
    selectStartDate: (state, action: PayloadAction<Date | null>) => {
      state.startDate = action.payload;
    },
    selectEndDate: (state, action: PayloadAction<Date | null>) => {
      state.endDate = action.payload;
    },
    selectStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    setAccountId: (state, action: PayloadAction<string>) => {
      state.accountId = action.payload;
    },
  },
});

export default downloadData.reducer;
export const { selectEntity, selectStartDate, selectEndDate, selectStatus, setAccountId } =
  downloadData.actions;
