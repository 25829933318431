/* eslint-disable camelcase */
import { useEffect, useRef } from 'react';
import { useAnalyticsService } from 'admin-portal-shared-services';
import segmentAnalytics from 'analytics';
import {
  ISegmentProps,
  ISegmentRequiredProps,
  SegmentEvent,
  SegmentEventsType,
} from 'interfaces/ISegment';
import { getUserLocalData } from 'services/userLocalData/UserLocalDataService';

const SegmentEvents = segmentAnalytics;

export const useSegmentAnalytics = (segmentKey: string): void => {
  const analyticsService = useAnalyticsService();

  useEffect(() => {
    analyticsService.load(segmentKey);
  }, [analyticsService, segmentKey]);
};

export const useSegmentEvent = (): ((event: SegmentEventsType, args?: ISegmentProps) => void) => {
  const { selectedCountry, selectedVendor } = getUserLocalData();
  const analyticsParamsRef = useRef<ISegmentRequiredProps>();
  analyticsParamsRef.current = {
    country: selectedCountry || null,
    vendor: selectedVendor.id || null,
    vendor_name: selectedVendor.name || null,
  };

  const event = (eventName: SegmentEventsType, args?: ISegmentProps): void => {
    (SegmentEvents as unknown as SegmentEvent)[eventName]({
      ...analyticsParamsRef.current,
      ...args,
    });
  };

  return event;
};

export default useSegmentAnalytics;
