import { v4 as uuid } from 'uuid';

const prefix = 'CSVIngestion';

const RequestTraceIdService = {
  create: (): string => `${prefix}-${uuid()}`,
  createBulk: (): string => `${prefix}Bulk-${uuid().slice(0, 7)}`,
};

export default RequestTraceIdService;
