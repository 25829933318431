import React, { useEffect, useLayoutEffect } from 'react';
import {
  CountryData,
  useCountryService,
  usePreferredLanguageV2,
  useUserMetadata,
} from 'admin-portal-shared-services';
import { CountryLangType } from 'admin-portal-shared-services/dist/hooks/useCountryService/useCountryService.types';
import { useAppHeaderService } from 'hooks/useAppHeaderService';
import { formatMessage } from 'i18n/formatters';
import { ICountry } from 'interfaces/ICountry';
import {
  getUserLocalData,
  isUserLocalDataValid,
} from 'services/userLocalData/UserLocalDataService';
import CountryModalView from './CountryModalView';

export interface ICountryModalState {
  isModalOpen: boolean;
  isModalLoading: boolean;
  hasError?: {
    errorTitle: string;
    errorDetails: string;
    errorCode?: string;
  };
}

const MODAL_STATUS = {
  INITIAL: {
    isModalOpen: false,
    isModalLoading: true,
    hasError: undefined,
  },
  LOADING: {
    isModalLoading: true,
  },
  STOP_LOADING: {
    isModalLoading: false,
  },
  OPEN: {
    isModalOpen: true,
  },
  CLOSE: {
    isModalOpen: false,
  },
  ERROR: {
    hasError: {
      errorTitle: '',
      errorDetails: '',
    },
  },
};

function CountryModal(): JSX.Element {
  const userData = getUserLocalData();
  const { setAppHeaderConfig } = useAppHeaderService();
  const { preferredLanguage } = usePreferredLanguageV2();
  const { INITIAL, STOP_LOADING, OPEN, CLOSE, ERROR } = MODAL_STATUS;
  const [countriesAvailable, setCountriesAvailable] = React.useState<ICountry[]>([]);
  const [modalStatus, setStatus] = React.useState<ICountryModalState>(INITIAL);
  const {
    data: countriesData,
    hasError: hasCountriesError,
    isLoading: isCountriesLoading,
  } = useCountryService();
  const {
    data: metadata,
    hasError: hasMetadataError,
    isLoading: isMetadataLoading,
  } = useUserMetadata();

  const setModalStatus = (
    newState: Partial<ICountryModalState>[],
    errorObj?: ICountryModalState['hasError']
  ) => {
    const mergedObj = newState.reduce((previousState, currentState) => ({
      ...previousState,
      ...currentState,
    }));
    if (errorObj) {
      const updatedErrorObj = { ...mergedObj, hasError: errorObj };
      setStatus((prevState) => ({ ...prevState, ...updatedErrorObj }));
      return;
    }
    setStatus((prevState) => ({ ...prevState, ...mergedObj }));
  };

  const getCountryOptions = () => {
    const vendors = metadata?.vendors || [];
    const allCountries = vendors.map((vendor) => vendor.country as string);
    return [...new Set(allCountries)].sort();
  };

  const onSelectCountry = (country: string) => {
    const countryOptions = getCountryOptions();
    setAppHeaderConfig({
      countryOptions,
      defaultCountry: country,
    });
    setModalStatus([CLOSE]);
  };

  const setCountryData = () => {
    const countryOptions = getCountryOptions();
    const hasCountriesData = !!(countriesData as CountryData[])?.length && !!countryOptions?.length;
    if (hasCountriesData) {
      if (!isUserLocalDataValid(userData.countriesAvailable, countryOptions)) {
        const getCountryName = (countryCode: string) => {
          const foundCountry = (countriesData as CountryData[]).find(
            (country) => country.code === countryCode
          );
          const userLanguage = preferredLanguage?.split('-')[0] as keyof CountryLangType;
          return foundCountry?.name?.[userLanguage] || foundCountry?.name?.en || countryCode;
        };

        const countries = countryOptions
          .map((countryCode) => ({
            name: getCountryName(countryCode),
            code: countryCode,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

        setCountriesAvailable(countries);
        setModalStatus([OPEN, STOP_LOADING]);
      } else {
        setAppHeaderConfig({
          countryOptions: userData.countriesAvailable,
          defaultCountry: userData.selectedCountry,
          defaultVendor: userData.selectedVendor.id,
        });
      }
    } else {
      setModalStatus([OPEN, ERROR, STOP_LOADING], {
        errorTitle: formatMessage({ id: 'ErrorMessages.NONE_COUNTRIES_AVAILABLE' }),
        errorDetails: formatMessage({ id: 'ErrorMessages.CONTACT_ADMINISTRATOR' }),
      });
    }
  };

  useLayoutEffect(() => {
    if (!userData.countriesAvailable.length) {
      setModalStatus([OPEN]);
    }
  }, []);

  useEffect(() => {
    if (!isCountriesLoading && !isMetadataLoading) {
      if (!hasCountriesError && !hasMetadataError) {
        setCountryData();
      } else {
        setModalStatus([OPEN, ERROR, STOP_LOADING], {
          errorTitle: formatMessage({ id: 'ErrorMessages.FAIL_TO_LOAD_COUNTRIES' }),
          errorDetails: formatMessage({ id: 'ErrorMessages.CONTACT_ADMINISTRATOR' }),
        });
      }
    }
  }, [isCountriesLoading, isMetadataLoading]);

  return (
    <CountryModalView
      modalStatus={modalStatus}
      countriesAvailable={countriesAvailable}
      onSelectCountry={onSelectCountry}
    />
  );
}

export default CountryModal;
