/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthenticationService } from 'admin-portal-shared-services';
import Axios, { CancelTokenSource } from 'axios';
import { formatMessage } from 'i18n/formatters';
import { getApiHost } from '../services/host/HostService';

declare module 'axios' {
  export interface AxiosError {
    hasError?: boolean;
  }
}
export interface IRequest {
  url: string;
  body?: any;
  config?: any;
}

export const initialAxios = Axios.create();

const authentication = useAuthenticationService();

authentication.enhancedAxios(initialAxios, {
  headers: [
    {
      'Accept-Language': 'en-US',
    },
  ],
});

const getCancelError = (error: any) => {
  const { message } = error;
  const messageCode = message.split('|')[0].trim();
  switch (messageCode) {
    case 'BSE-02':
      return {
        hasError: true,
        response: {
          data: {
            code: 'BSE-02',
            message: formatMessage({ id: 'ErrorMessages.IMPORT_CANCELED' }),
          },
        },
      };
    case 'BSE-03':
      return {
        hasError: true,
        response: {
          data: {
            code: 'BSE-03',
            message: formatMessage({ id: 'ErrorMessages.IMPORT_INTERRUPTED' }),
            detail: formatMessage({ id: 'ErrorMessages.IMPORT_INTERRUPTED_DETAILS' }),
          },
        },
      };
    default:
      return { hasError: true, ...error };
  }
};

const catchFn = (error: any) => {
  if (Axios.isCancel(error)) {
    return getCancelError(error);
  }
  return {
    hasError: true,
    ...error,
  };
};

const Api = {
  get: ({ url, config }: IRequest): Promise<any> => {
    return initialAxios.get(`${getApiHost()}${url}`, config).catch((error) => ({
      hasError: true,
      ...error,
    }));
  },

  post: ({ url, body, config }: IRequest): Promise<any> =>
    initialAxios.post(`${getApiHost()}${url}`, body, config).catch(catchFn),

  put: ({ url, body, config }: IRequest): Promise<any> =>
    initialAxios.put(`${getApiHost()}${url}`, body, config).catch(catchFn),

  delete: ({ url, body, config }: IRequest): Promise<any> =>
    initialAxios
      .delete(`${getApiHost()}${url}`, {
        ...config,
        data: body,
      })
      .catch(catchFn),

  patch: ({ url, body, config }: IRequest): Promise<any> =>
    initialAxios.patch(`${getApiHost()}${url}`, body, config).catch(catchFn),

  generateRequestSource: (): CancelTokenSource => {
    const { CancelToken } = Axios;
    return CancelToken.source();
  },
};

export default Api;
