import { createContext, ReactNode, useMemo, useState } from 'react';

export interface IUserInfoContext {
  selectedCountry: string;
  selectedVendor: string;
  isImportInProgress: boolean;
  setUserInfoContext: (newEntry: Partial<IUserInfoContext>) => void;
}

interface IUserInfoProvider {
  children: ReactNode;
}

const UserInfoContext = createContext<IUserInfoContext>({} as IUserInfoContext);

UserInfoContext.displayName = 'UserInfoContext';

const initialState = {
  selectedCountry: '',
  selectedVendor: '',
  isImportInProgress: false,
} as IUserInfoContext;

export function UserInfoProvider({ children }: IUserInfoProvider) {
  const [userInfoContext, setInfoContext] = useState<IUserInfoContext>(initialState);

  const setUserInfoContext = (newEntry: Partial<Omit<IUserInfoContext, 'setUserInfoContext'>>) => {
    setInfoContext((prevState) => ({
      ...prevState,
      ...newEntry,
    }));
  };
  const state = useMemo(
    () => ({
      ...userInfoContext,
      setUserInfoContext,
    }),
    [userInfoContext]
  ) as IUserInfoContext;

  return <UserInfoContext.Provider value={state}>{children}</UserInfoContext.Provider>;
}

export default UserInfoContext;
