import { USER_LOCAL_STORAGE_EXPIRATION_TIME } from 'consts/configs';
import { IVendor } from 'interfaces/IVendor';
import { equals, fromBinary, toBinary } from './helpers';

export interface IUserLocalData {
  countriesAvailable: string[];
  selectedCountry: string;
  selectedVendor: IVendor;
  expiration: number | null;
}

const nameLocalItem = 'BS_UserDataV3' as string;

const getUserLocalData = (): IUserLocalData => {
  const stringItemUserLocalData =
    sessionStorage.getItem(nameLocalItem) || localStorage.getItem(nameLocalItem) || null;
  if (stringItemUserLocalData && stringItemUserLocalData.length) {
    const userLocalDataDecoded: IUserLocalData = JSON.parse(
      fromBinary(atob(stringItemUserLocalData))
    );
    return userLocalDataDecoded;
  }
  return {
    countriesAvailable: [],
    selectedCountry: '',
    selectedVendor: {} as IVendor,
    expiration: null,
  };
};

const setUserLocalData = ({
  countriesAvailable,
  selectedCountry,
  selectedVendor,
}: Partial<IUserLocalData>): void => {
  const userLocalData = getUserLocalData();
  const itemUpdateUserData: IUserLocalData = {
    countriesAvailable: countriesAvailable || userLocalData.countriesAvailable,
    selectedCountry: selectedCountry || userLocalData.selectedCountry,
    selectedVendor: selectedVendor || userLocalData.selectedVendor,
    expiration:
      userLocalData.expiration ?? new Date().getTime() + USER_LOCAL_STORAGE_EXPIRATION_TIME,
  };
  const itemUpdateUserLocalDataEncoded = btoa(toBinary(JSON.stringify(itemUpdateUserData)));
  sessionStorage.setItem(nameLocalItem, itemUpdateUserLocalDataEncoded);
  localStorage.setItem(nameLocalItem, itemUpdateUserLocalDataEncoded);
};

const removeUserLocalDataItem = (itemName = nameLocalItem): void => {
  sessionStorage.removeItem(itemName);
  localStorage.removeItem(itemName);
};

const isUserLocalDataValid = <T>(localCountryArray: T, requestCountryArray: T): boolean => {
  const userLocalData = getUserLocalData();
  const { expiration } = userLocalData;
  if (expiration && expiration < new Date().getTime()) {
    removeUserLocalDataItem();
    return false;
  }
  if (!equals(localCountryArray, requestCountryArray)) {
    removeUserLocalDataItem();
    return false;
  }
  return true;
};

export { getUserLocalData, setUserLocalData, isUserLocalDataValid, removeUserLocalDataItem };
