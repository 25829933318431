import { globalCss } from './stitches';

export const globalStyles = globalCss({
  'header#mfe-content-header > div > div': {
    paddingLeft: '$6',
    paddingRight: '$6',
  },
  'header#mfe-content-header > div h1': {
    display: 'block',
    height: 'auto',
    lineHeight: '40px',
  },
  'header#mfe-content-header.hideTitle > div h1': {
    display: 'none',
  },
  'table[role="table"] p': {
    lineHeight: '1.2',
  },
});
