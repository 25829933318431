import { LoadingBuzz } from '@hexa-ui/components';
import { styled } from 'themes';

export const LoadingIcon = styled(LoadingBuzz, {
  margin: 'auto',
});

export const LoadingContainer = styled('div', {
  position: 'absolute',
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
  backgroundColor: '#FAFAFA',
  display: 'flex',
});

export const AppContainer = styled('div', {
  display: 'flex',
  width: '100%',
  minHeight: 'calc(100vh - 2 * 56px)',
  '@desktopSmall': {
    minHeight: 'calc(100vh - (56px + 128px))',
  },
});

export const Content = styled('div', {
  flex: 1,
  width: '100%',
});
