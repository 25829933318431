import { createInstance, ReactSDKClient, setLogger } from '@optimizely/react-sdk';
import { useLogService } from 'admin-portal-shared-services';
import { store } from 'app/store';
import { setIsError } from 'features/featureToggle/featureToggle';

interface OptimizelyReturn {
  optimizelyInstance: (optimizelyKey: string) => ReactSDKClient;
}

const Optimizely = (): OptimizelyReturn => {
  const logService = useLogService();

  setLogger(null);

  const handleError = (error: Error) => {
    store.dispatch(setIsError());

    logService.error(error);
  };

  const optimizelyInstance = (optimizelyKey: string): ReactSDKClient =>
    createInstance({
      sdkKey: optimizelyKey,
      datafileOptions: {
        autoUpdate: false,
      },
      eventBatchSize: 10,
      eventFlushInterval: 1000,
      errorHandler: {
        handleError,
      },
    });

  return { optimizelyInstance };
};

export default Optimizely;
