import { BaseQueryArg, BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import startCase from 'lodash.startcase';
import { methodsParserObject } from 'services/entities/EntitiesService';
import RequestTraceIdService from 'services/requestTraceId/RequestTraceIdService';

export interface HistoryResponse {
  user: {
    name: string;
    email: string;
  };
  vendor: {
    name: string;
    id: string;
  };
  country: {
    name: string;
    code: string;
  };
  file: {
    lines: number;
    columns: number;
    delimiter: string;
  };
  status: {
    name: string;
    description: string;
    lines: HistoryLines[];
  };
  entity: {
    name: string;
    version: string;
    operation: string;
  };
  requestTraceId: string;
  createdAt: string;
}

export interface HistoryLines {
  line: number;
  field: string;
  message: string;
}

export interface HistoryParams {
  page: number;
  country: string;
  vendor: string;
}

export interface HistoryStatus {
  success: boolean;
  description: string;
  lines: HistoryLines[];
}
export interface HistoryParsed {
  pagination: {
    currentPage: number;
    total: number;
    showPageSizeSelector: boolean;
    showQuantityIndicator: boolean;
  };
  history: {
    user: string;
    entity: string;
    action: string;
    lines: number | string;
    success: boolean;
    status: HistoryStatus;
    traceId: string;
    date: string;
  }[];
}

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatInTimeZone = (date: string): string =>
  format(utcToZonedTime(parseISO(date), tz), 'yyyy-MM-dd HH:mm:ss', { timeZone: tz });

export const getHistoryQuery = ({
  page,
  country,
  vendor,
}: HistoryParams): BaseQueryArg<BaseQueryFn> => ({
  url: `/upload/history?page=${page}&pageSize=15&countries=${country}&vendors=${vendor}`,
  method: 'GET',
  headers: {
    requestTraceId: RequestTraceIdService.create(),
  },
});

export const getHistoryTransformResponse = (
  response: HistoryResponse[],
  meta: { [key: string]: string }
): HistoryParsed => {
  const currentPage = Number(meta['pageable-current-page']);
  const total = Number(meta['pageable-total-elements']);
  const history = response.map((item) => ({
    user: item.user.name,
    lines: item?.file?.lines ?? '-',
    entity: startCase(item.entity.name),
    action: `${methodsParserObject[item.entity.operation.toLowerCase()]} (${
      item.entity.version
    }) - ${item.entity.operation}`,
    success: item.status.name === 'SUCCESS',
    status: {
      success: item.status.name === 'SUCCESS',
      description: item.status.description,
      lines: item.status.lines || [],
    },
    traceId: item.requestTraceId,
    date: formatInTimeZone(`${item.createdAt}Z`),
  }));

  return {
    history,
    pagination: {
      currentPage: currentPage + 1,
      total,
      showPageSizeSelector: false,
      showQuantityIndicator: true,
    },
  };
};
